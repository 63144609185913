<template>
  <HAWPageContent :breadcrumb="false">
    <CourseList />
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";
import CourseList from "./partials/CourseList";

export default {
  components: {
    CourseList,
    HAWPageContent,
  },
};
</script>
