<template>
  <div>
    <div class="mt-3">
      <StatusFilter v-model="statusFilter" />
    </div>

    <BaseRow v-if="isLoading" cols="12">
      <BaseCol cols="12" class="flex justify-content-center py-3">
        <BaseProgressSpinner />
      </BaseCol>
    </BaseRow>

    <BaseRow v-else-if="meta.total === 0">
      <BaseCol cols="12" class="text-center pb-6 pt-8">
        <BaseParagraph v-if="statusFilter">
          {{ $t("uc.my.courses.list.noDataForStatus") }}
        </BaseParagraph>
        <BaseParagraph v-else>{{ $t("uc.my.courses.list.noData") }}</BaseParagraph>
      </BaseCol>
    </BaseRow>

    <DataView
      v-bind="$attrs"
      layout="grid"
      :value="courses"
      :paginator="true"
      :lazy="true"
      :rows="12"
      :total-records="meta.total"
      @page="onPage($event)"
      v-else
    >
      <template #grid="slotProps">
        <BaseCol class="course-list-col flex">
          <BaseCard class="px-3 m-3 w-full">
            <BaseRow>
              <BaseCol cols="12">
                <CourseThumbnail :course="slotProps.data" />
              </BaseCol>
              <BaseCol cols="12">
                <CourseProgressBar :course="slotProps.data" />
              </BaseCol>
              <BaseCol cols="12">
                <BaseHeadline size="6" no-margin>{{ slotProps.data.title }}</BaseHeadline>
              </BaseCol>
              <BaseCol cols="12">
                <CourseTopics :course-topics="slotProps.data.courseTopics" />
              </BaseCol>
              <BaseCol cols="12">
                <BaseRow class="flex justify-content-between mt-1" :no-gutter="true">
                  <BaseCol cols="shrink" class="flex">
                    <div style="color: var(--gray-500)">
                      {{ getTeacherName(slotProps.data.teacher) }}
                    </div>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseRow class="flex justify-content-between mt-3 align-items-center" :no-gutter="true">
              <BaseCol class="auto">
                <ChaptersCompletedLabel :course="slotProps.data" />
              </BaseCol>
              <BaseCol cols="shrink" class="flex">
                <ShowCourseButton :course="slotProps.data" />
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
      </template>
    </DataView>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { ref, watch } from "vue";
import DataView from "primevue/dataview";
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import CourseThumbnail from "@/modules/user-course-uc/components/CourseThumbnail/CourseThumbnail";
import ShowCourseButton from "./partials/ShowCourseButton";
import ChaptersCompletedLabel from "../../../../../../components/ChaptersCompletedLabel";
import StatusFilter from "./partials/StatusFilter";
import CourseProgressBar from "@/modules/haw-components-hac/Base/CourseProgressBar";

export default {
  name: "CourseList",
  components: {
    CourseProgressBar,
    StatusFilter,
    ChaptersCompletedLabel,
    ShowCourseButton,
    CourseThumbnail,
    CourseTopics,
    DataView,
  },
  setup() {
    const courses = ref([]);
    const meta = ref({});
    const statusFilter = ref(null);

    const { docs, callApi, isLoading } = useApi({
      module: "uc",
      method: "listCourses",
      onMount: true,
      params: {
        page: 1,
        itemsPerPage: 12,
        onlyOwned: true,
      },
      onSuccess: () => {
        courses.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    const getStatusFilter = () => {
      return statusFilter.value ? [statusFilter.value] : null;
    };

    const onPage = (event) => {
      callApi({
        page: event.page + 1,
        courseProgressionStatusIds: getStatusFilter(),
      });
    };

    watch(statusFilter, () => {
      callApi({
        courseProgressionStatusIds: getStatusFilter(),
      });
    });

    return {
      courses,
      onPage,
      meta,
      isLoading,
      getTeacherName,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.course-list-col {
  min-width: 100%;
  max-width: 100%;

  @media only screen and (min-width: 1000px) {
    min-width: 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 1600px) {
    min-width: 33%;
    max-width: 33%;
  }

  @media only screen and (min-width: 1800px) {
    min-width: 25%;
    max-width: 25%;
  }
}
</style>
