<template>
  <BaseButton @click="pushToCourse" :label="label" />
</template>

<script>
export default {
  name: "ShowCourseButton",
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        courseOwnership: {
          lastOpenedAt: null,
        },
      }),
    },
  },
  computed: {
    label() {
      if (this.course.courseOwnership.lastOpenedAt) {
        return "uc.my.courses.list.showCourse";
      }

      return "uc.my.courses.list.showNewCourse";
    },
  },
  methods: {
    pushToCourse() {
      try {
        this.$router.push({
          name: "MyCoursesShow",
          params: {
            courseId: this.course.id,
          },
        });
      } catch {
        return;
      }
    },
  },
};
</script>
