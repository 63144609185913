<template>
  <BaseRow class="justify-content-end pb-4">
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="!statusFilter"
        label="uc.my.courses.statusFilter.filterAll"
        @click="setStatusFilter(null)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === COURSE_PROGRESSION_STATUS.OPEN"
        label="uc.my.courses.statusFilter.open"
        @click="setStatusFilter(COURSE_PROGRESSION_STATUS.OPEN)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === COURSE_PROGRESSION_STATUS.IN_PROGRESS"
        label="uc.my.courses.statusFilter.inProgress"
        @click="setStatusFilter(COURSE_PROGRESSION_STATUS.IN_PROGRESS)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === COURSE_PROGRESSION_STATUS.DONE"
        label="uc.my.courses.statusFilter.done"
        @click="setStatusFilter(COURSE_PROGRESSION_STATUS.DONE)"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import COURSE_PROGRESSION_STATUS from "@/modules/user-course-uc/constants/COURSE_PROGRESSION_STATUS";
import StatusFilterButton from "@/modules/haw-components-hac/Base/StatusFilterButton";

export default {
  name: "StatusFilter",
  components: { StatusFilterButton },
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: null,
    },
  },

  setup() {
    return {
      COURSE_PROGRESSION_STATUS,
    };
  },

  computed: {
    statusFilter: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  methods: {
    setStatusFilter(value) {
      this.statusFilter = value;
    },
  },
};
</script>
