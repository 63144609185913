<template>
  <BaseProgressBar slim :value="courseProgress" />
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      required: true,
    },
  },

  computed: {
    courseProgress() {
      return (this.course.completedCourseChaptersCount / this.course.courseChaptersCount) * 100;
    },
  },
};
</script>
