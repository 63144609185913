<template>
  <div class="text-500">
    <BaseIcon :icon="attributes.icon" class="mr-2" :class="attributes.class" />
    {{ attributes.label }}
  </div>
</template>

<script>
import BaseIcon from "@/modules/base-components-bc/Base/BaseIcon";

export default {
  components: { BaseIcon },
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        courseChaptersCount: null,
        completedCourseChaptersCount: null,
      }),
    },
  },
  computed: {
    attributes() {
      const attributes = {
        label: null,
        icon: null,
        class: null,
      };

      attributes.label = this.$t("uc.my.courses.list.completedChapters", {
        completed: this.course.completedCourseChaptersCount,
        count: this.course.courseChaptersCount,
      });

      if (this.course.completedCourseChaptersCount === this.course.courseChaptersCount) {
        attributes.icon = "pi pi-check";
        attributes.class = "text-success";
      } else {
        attributes.icon = "pi pi-pencil";
        attributes.class = "text-500";
      }

      return attributes;
    },
  },
};
</script>
